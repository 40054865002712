import React from 'react'
import { graphql } from 'gatsby'
import HubspotForm from 'react-hubspot-form'
import styled from '@emotion/styled'
import Layout from '../layouts/layout'
import Hero from '../components/Hero/Hero'
import Container from '../components/container'
import SEO from '../components/seo'
import Button from '../components/Button/Button'

const Row = styled.div`
    @media (min-width: 769px) {
        display: flex;
    }
`

const SidebarWidget = styled.div`
    width: 300px;
    max-width: 100%;
    background-color: var(--color-licorice);
    color: #fff;
    align-self: flex-start;
    border-radius: 5px;
    padding: 1rem 1rem 2rem;
`
const SidebarSubtitle = styled.span`
    font-family: var(--font-heading);
    font-weight: 700;
    color: var(--color-light-blue);
`
const SidebarTitle = styled.h3`
    font-size: 2rem;
    font-weight: 700;
`

export default function GetStarted({ data }) {
    return (
        <Layout>
            <SEO title="Get Started" />
            <Hero image={data.heroImage} title="Get Started" />
            <Container>
                <Row>
                    <div css={{ flex: 1 }}>
                        <HubspotForm
                            className="hubspot"
                            portalId="3621575"
                            key="7d2bb535-deab-41b8-866b-cf36f4e8cf3d"
                            formId="7d2bb535-deab-41b8-866b-cf36f4e8cf3d"
                            css={{ flex: 1 }}
                        />
                    </div>
                    {/* <SidebarWidget>
                        <SidebarSubtitle>Now Available</SidebarSubtitle>
                        <SidebarTitle>
                            Free RISC-V Evaluation Platform for Xilinx FPGAs
                        </SidebarTitle>
                        <p>
                            Evaluate RISC-V cores rapidly, Compare RISC-V cores
                            for implementation on Xilinx FPGAs. Move to
                            development seamlessly.
                        </p>
                        <Button to="https://info.bluespec.com/explorer-kit">
                            GET ACCESS
                        </Button>
                    </SidebarWidget> */}
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query {
        heroImage: file(relativePath: { eq: "get-started-bg@2x.png" }) {
            ...heroImage
        }
    }
`
